import { Show } from "solid-js";

type ShellProps = {
  title?: string;
  url?: string;
};
export default function CardShell(props: ShellProps) {
  return (
    <div class="loading-card">
      <div class="spinner">
        <i aria-hidden="true" class="loading-spinner" />
      </div>
      Chargement…
      <Show when={props.title && props.url}>
        <a href={props.url}>{props.title}</a>
      </Show>
    </div>
  );
}
